import constate from 'constate';
import { useReducer, useEffect } from 'react';
import axios from 'axios';

const api = axios.create({
    baseURL: process.env.GATSBY_API_URL,
    timeout: 2000,
});

export const completeInvite = async (token, uid, body, callback) => {
    const result = await api.post(`/users/auth/invitation/${uid}/${token}`, body, { withCredentials: true });
    if (result.status !== 200) {
        return null;
    } else {
        const d = result.data;
        callback();
        return d
    }
}

// export const [ResetsInvitesProvider, useResetsInvitesContext] = constate(useResetsInvites);
